/* eslint-disable react/no-unknown-property */
/* eslint-disable @next/next/no-sync-scripts */

import '../styles/main.scss';
import i18n from '../i18n';
import Head from 'next/head'
import parse from 'html-react-parser';
import { useDocumentReferrerPolyfill } from '../client-event-tracking/hooks';
import { setAssetPath } from '../utils/helpers';
import { PageInfoContext } from '../context/PageInfoContext';
require.context('../public/static/locales', true, /\.json$/);

// local development - a11y logging
import '../utils/reactAxeSetup';

const SOCIAL_META_IMAGE_WIDTH = 1200;
const SOCIAL_META_IMAGE_HEIGHT = 630;
const SITE_DESCRIPTION = 'Get your free daily horoscope. Discover what&#39;s in store for your astrology sign for the day, your week in romance and more.';

function Application({ Component, pageProps }) {
  const { host } = pageProps;
  const isUK = host === 'www.huffingtonpost.co.uk';
  useDocumentReferrerPolyfill();

  return (
    <>
      <Head>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Source+Code+Pro:wght@600&display=swap" rel="stylesheet" />
        <link rel="stylesheet" href="https://use.typekit.net/qau1kgr.css"></link>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0" />
        <script
          type={'text/javascript'}
          src={`${setAssetPath('/static/scripts/waitForGlobal.js')}`}
        />
        <meta property="og:image" content={`${setAssetPath('/images/social-images/facebook.jpg', host)}`} />
        <meta property="og:image:url" content={`${setAssetPath('/images/social-images/facebook.jpg', host)}`} />
        <meta property='og:image:width' content={SOCIAL_META_IMAGE_WIDTH} />
        <meta property='og:image:height' content={SOCIAL_META_IMAGE_HEIGHT} />
        <meta property='twitter:image:width' content={SOCIAL_META_IMAGE_WIDTH} />
        <meta property='twitter:image:height' content={SOCIAL_META_IMAGE_HEIGHT} />
        <meta property="og:description" content={SITE_DESCRIPTION} />
        <meta name="twitter:description" content={SITE_DESCRIPTION} />
        <meta content={SITE_DESCRIPTION} name="description"></meta>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content={isUK ? '@huffpostuk' : '@huffpost'} />
        <meta name="twitter:image" content={`${setAssetPath('/images/social-images/twitter.jpg', host)}`} />
        <link url='https://cdn.cookielaw.org/scripttemplates/otSDKStub.js' rel='preload' as='script' />
        <link url='https://cdn.cookielaw.org/opt-out/otCCPAiab.js' rel='preload' as='script' />
        <script src='https://cdn.cookielaw.org/scripttemplates/otSDKStub.js' type="text/javascript" charset="UTF-8" data-domain-script={isUK ? '9cfa1187-070d-4b97-a3fe-6aa7d66aedad' : 'a784447a-58ed-4e91-ba84-d811aafcc0b3'}>
        </script>
        <script src='https://cdn.cookielaw.org/opt-out/otCCPAiab.js' type="text/javascript" ccpa-opt-out-ids="SPD_BG" ccpa-opt-out-geo="us" ccpa-opt-out-lspa="false">
        </script>
        <>
          {pageProps?.ads?.head && parse(pageProps.ads.head)}
        </>
      </Head>
      <PageInfoContext.Provider value={{ ...pageProps.pageInfo }}>
        <Component {...pageProps} />
      </PageInfoContext.Provider>
    </>
  );
}

export default i18n.appWithTranslation(Application);
