import { CLUSTER } from '../constants';

export function setAssetPath(path, host = null) {
  // TODO: Add better dev edition handling
  const isDev = CLUSTER === 'dev';
  const newPath = isDev ? path : `/horoscopes${path}`;
  return !isDev && host ? `https://${host}${newPath}` : newPath;
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function setWebviewPath(url, isWebview) {
  if (!isWebview) {
    return url;
  }

  // Check if the URL is absolute or relative
  const isAbsolute = url.startsWith('http://') || url.startsWith('https://');

  if (isAbsolute) {
    const urlObj = new URL(url);
    urlObj.pathname = urlObj.pathname.endsWith('/')
      ? `${urlObj.pathname}webview`
      : `${urlObj.pathname}/webview`;
    return urlObj.toString();
  } else {
    // For relative URLs, split the URL into the path and query string
    const [path, queryString] = url.split('?');
    const newPath = path.endsWith('/') ? `${path}webview` : `${path}/webview`;

    // If there's a query string, add it back to the new path
    return queryString ? `${newPath}?${queryString}` : newPath;
  }
}

const makeMapiEntryLink = (entryId, edition) => {
  return `hpentry://?edition_id=${edition}&entry_id=${entryId}`;
};

const makeMapiBnpLink = (name, title, edition) => {
  return `hpbignews://?name=${name}&edition=${edition}&title=${title}`;
}

const makeMapiSectionLink = (name, title, edition) => {
  return `hpsection://?name=${name}&edition=${edition}&title=${title}`;
}

export function setWebviewHPLinks(url, edition) {
  const cambriaEntryStrRegex = /[a-z0-9-]+\/(?:[a-z0-9-]*)_(?:a|b|l|n|[a-z]{2})_([a-f0-9]{1,24})/ig;
  const bnpStrRegex = /(https?:\/\/[a-zA-Z]+\.huffingtonpost\.[^>]+\/(?:news|topic)\/([\w\d_\-%]+))(.*?)/ig;
  const sectionCambriaStrRegex = /(https?:\/\/(?:[a-zA-Z0-9.]+)\.huffpost\.[^>]+\/(?:life|news|impact|entertainment|voices|section)\/(?!.*topic)([\w\d_\-%]+))(.*?)/ig;
  const bnpCambriaStrRegex = /(https?:\/\/(?:[a-zA-Z0-9.]+)\.huffpost\.[^>]+\/(?:news|topic)\/([\w\d_\-%]+))(.*?)/ig;

  const entryRegexMatch = cambriaEntryStrRegex.exec(url);
  if (entryRegexMatch) {
    const entryId = entryRegexMatch[1];
    return `${makeMapiEntryLink(entryId, edition)}`;
  }

  const bnpStrRegexMatch = bnpStrRegex.exec(url);
  if (bnpStrRegexMatch) {
    const name = bnpStrRegex[2];
    const title = bnpStrRegex[3];
    return `${makeMapiBnpLink(name, title, edition)}`;
  }

  const sectionCambriaStrRegexMatch = sectionCambriaStrRegex.exec(url);
  if (sectionCambriaStrRegexMatch) {
    const name = sectionCambriaStrRegexMatch[2];
    const title = sectionCambriaStrRegexMatch[3];
    return `${makeMapiSectionLink(name, title, edition)}`;
  }

  const bnpCambriaStrRegexMatch = bnpCambriaStrRegex.exec(url);
  if (bnpCambriaStrRegexMatch) {
    const name = sectionCambriaStrRegexMatch[2];
    const title = sectionCambriaStrRegexMatch[3];
    return `${makeMapiBnpLink(name, title, edition)}`;
  }

  return url;
}